import React from 'react';
import { Link } from 'gatsby';
// import Img from 'gatsby-image';
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ImgBox from '../ImgBox';
import missingImage from './missing_image.jpg'
import { formatMoney, numberWithCommas } from '../../helpers/format-money'

function getSafe(fn, defaultVal) {
    try {
        return fn();
    } catch (e) {
        return defaultVal;
    }
}

const StyledCard = styled.div`
    box-sizing: border-box;
    padding: 5px;
    display: inline-block;
    width: 210px;
    height: 280px;
    background-color: #f8f8f8;
    border: 1px solid lightgrey;
    box-shadow: 0 0 3px rgba(0,0,0,.25);
    position: relative;
    text-align: center;
    transition: .25s;
    ${({ auxStyle }) => auxStyle}
    &:hover {
        box-shadow: 0 0 3px rgba(0,0,0,.25), 0 0 10px rgba(255,255,255,1);
    }
`
const Title = styled.div`
    /* text-transform:  uppercase; */
    font-family: 'Roboto Condensed', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-align: center;
    margin-top: 10px;
`

const Button = styled(props => <Link {...props} />)`
    text-decoration: none;
    color: #555;
    font-family: 'Roboto Condensed', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: .8em;
    line-height: 1.8em;
    margin: 5px;
    border: 1px solid slategrey;
    border-radius: 12px;
    width: 125px;
    height: 24px;
    text-align: center;
    display: inline-block;
    transition: .25s;
    &:hover {
        background-color: lightslategrey;
        color: #fafafa;
    }
`
const GrowImg = styled.div`
    overflow: hidden;
    height: 200px;
    width: 200px;
    &:hover > #grower {
        transition: .2s;
        transform: scale(1.05);
    }
`

const DomainCard = props => {
    const { node: { frontmatter: {
        current_price,
        custom_link,
        normal_price,
        cover_image: { childImageSharp: { gatsbyImageData } },
        domain_name,
    }, slug }, auxStyle } = props;

    var buttonLabel = current_price || normal_price;
    const formatLabel = buttonLabel ? (Number(buttonLabel) ? `$${numberWithCommas(buttonLabel)}` : buttonLabel) : 'MAKE OFFER'

    const href = custom_link || `/details/${slug}`;
    // const href = custom_link ? custom_link : `/details/${domain_name.toLowerCase()}`;

    return (
        <StyledCard auxStyle={auxStyle} >
            <Link to={href}>
                <GrowImg>
                    <div id="grower">
                        {gatsbyImageData ?
                            // {cover_image && cover_image.localFile ?
                            <GatsbyImage
                                // fixed={cover_image.localFile.childImageSharp.fixed}
                                image={gatsbyImageData}
                                alt={domain_name}
                            /> :
                            <ImgBox
                                src={missingImage}
                                width={200}
                                height={200}
                                style={{
                                    border: '1px solid grey',
                                    display: 'inline-block',
                                    boxSizing: 'border-box'
                                }}
                            />
                        }
                    </div>
                </GrowImg>
            </Link>
            <Title>{domain_name}</Title>
            <Button to={href}>{formatLabel}</Button>
        </StyledCard>
    )
};

DomainCard.propTypes = {
    image: PropTypes.string,
    href: PropTypes.string,
    title: PropTypes.string,
    buttonLabel: PropTypes.string,
    auxStyle: PropTypes.object,
};

export default DomainCard;