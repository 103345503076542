const formatMoney = (amount, decimals = 2) => {
    if (isNaN(Number(amount))) return null;
    // convert to number, then string
    return parseFloat(amount).toFixed(decimals);
};

const numberWithCommas = num => {
    if (!num || isNaN(Number(num))) return null;
    num = num.toString();
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// 
const numParts = numString => {
    // toString converts (1.50) to '1.5'
    const nullArray = [null, null, null];
    if (!numString) return nullArray;
    let numMatches = numString.toString().match(/^(\d[\d|,]*)\.?(\d*)/);
    return nullArray.map((item, i) => numMatches[i] || item);
}
  // Array(3) [ "5925.84", "5925", "84" ]


// sequence:
//   formatMoney()
//   numParts()
//   numberWithCommas()

export {formatMoney, numberWithCommas, numParts}