import React, { useEffect, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Layout from '../layout'
import Container from '../Container'
import OutlinedContainer from '../OutlinedContainer'
// import ImgBox from '../ImgBox'
// import DomainDetailsPane from '../DomainDetailsPane';
// import loadScript from '../helpers/load-script';
import SEO from '../seo';
import DomainCard from '../DomainCard';

// import premiumDomainImage from './premium-domain.jpg';

const Cols = styled.div`
    & > * {
        /* border: 1px solid grey; */
        /* width: 100%; */
        flex: 1;
    }
    display: flex;
    flex-direction: row;
    /* justify-content: flex-start; */
    /* align-items: flex-start; */
    flex-wrap: wrap;
    @media screen and (max-width: 850px) {
        display: block;
    }
`

const PremiumDomainsPage = (props) => {

  // const {
  //   data: {
  //     allDirectusPremiumDomain: {
  //       edges: domains = []
  //     }
  //   }
  // } = props;

  // const filteredDomains = domains.filter(({ node: domain }) => !domain.hide_from_showcase);


  const title = 'Find a Great Premium Domain Name for Your Project.';
  const description = `With over 244 million registered domains, finding the perfect name can be difficult. Get your project going quickly with one of Domfeed's fantastic premium domain names. A great start with no work.`;

  useEffect(() => {
    // set the domain name in the form

    // console.log({ isLive });
    // setDomain(termExtractor({ domainTerm: window.location.hostname }).domainTerm)
    // setTld(termExtractor({ domainTerm: window.location.hostname }).domainTld)

    // [] this should use the CMS info passed in.
    window.document.title = title;
  }, []);


  const { allMdx: { edges } } = useStaticQuery(graphql`
    query {
      allMdx {
        edges {
          node {
            fields {
              source
            }
            id
            slug
            frontmatter {
              categories
              hide_from_showcase
              domain_name
              title
              current_price
              normal_price
              cover_image {
                id
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            body
          }
        }
      }
    }
`)

  
const filteredDomains = edges
.filter(({ node }) => node.fields.source === "domains")
.filter(({ node }) => !node.frontmatter.hide_from_showcase);


  return (
    <Fragment>
      <Layout overrideContent={<><h1>Premium Domain Names</h1></>}>
        <SEO
          title={title}
          author="@domfeed"
          imageUrl={'/img/where-to-get-premium-domains.jpg'}
          description={description}
        />
        <Container >
          <OutlinedContainer style={{ width: '100%', marginTop: '20px' }}>
            <div style={{ textAlign: 'center' }}>
              {filteredDomains.map(({ node }, i) => {
                return (
                  <DomainCard
                    key={i}
                    auxStyle={{ margin: '10px' }}
                    node={node} />
                )

              })}
            </div>
          </OutlinedContainer>
        </Container>
      </Layout>
    </Fragment>
  )
};

PremiumDomainsPage.propTypes = {
};

export default PremiumDomainsPage;
